import { Form, Link, useActionData, useFetcher, useLoaderData, useLocation } from "@remix-run/react";
import { Field, Submit } from "~/components/Forms";
import { Logo } from "~/components/Logo";
import { z } from "zod";
import { useForm } from "@conform-to/react";
import { parse } from "@conform-to/zod";
import { ActionFunctionArgs, LoaderFunctionArgs, json } from "@remix-run/node";
import { authenticateUser } from "~/utils/authentication.server";
import { useLocale } from "remix-i18next/react";
import { useLocalization } from "~/hooks/localize";
import { Icon } from "@iconify/react/dist/iconify.js";
import { t } from "~/utils/locales";
import { useTurnstile } from "react-turnstile";
import { useEffect, useState } from "react";
import { env } from "~/utils/env.server";
import { TurnstileWidget } from "~/components/Turnstile";
import { validateTurnstile } from "~/utils/turnstile.server";

export async function loader({ request }: LoaderFunctionArgs) {
  return {
    env: {
      NODE_ENV: env.NODE_ENV
    }
  }
}


export const action = async ({ request }: ActionFunctionArgs) => {

  const formData = await request.formData()
  const outcome = await validateTurnstile(request, formData)

  if (env.NODE_ENV === 'production' && !outcome.success) return { error: 'NOT_HUMAN' }

  const ActionData = z.object({
    username: z.union([
      z.string().startsWith('NS', { message: await t('error_missingUser', request) }),
      z.string().startsWith('ns', { message: await t('error_missingUser', request) }),
      z.string().startsWith('NT', { message: await t('error_missingUser', request) }),
      z.string().startsWith('nt', { message: await t('error_missingUser', request) }),
      z.string().email()
    ]),
    password: z.string()
  })

  const actionData = ActionData.safeParse(Object.fromEntries(formData))

  if (!actionData.success) {
    return json({
      errors: actionData.error
    })
  }

  const { username, password } = actionData.data


  return authenticateUser(
    username,
    password,
    request
  );
};

export default function LoginPage() {
  const { env } = useLoaderData<typeof loader>()
  const [turnstileVerified, setTurnstileVerified] = useState(() => env.NODE_ENV === 'development' ? true : false)
  const turnstile = useTurnstile()
  const { errors, error } = useActionData<typeof action>() || {};
  const locale = useLocale()
  const { t } = useLocalization()
  const location = useLocation()
  useEffect(() => {
    if (error === 'NOT_HUMAN') {
      turnstile.reset()
    }
  }, [error])
  return (
    <div className="flex flex-col gap-8 md:min-w-[380px]">
      <div className="h-[80px] flex justify-center">
        <Logo />
      </div>
      <div>
        <div className="flex justify-between mb-4 items-baseline">
          <h1 className="text-2xl text-center">{t('signIn')}</h1>
          <Form
            method="POST"
            action={`/changeLanguage?redirectTo=${location?.pathname}`}
          // action={`/changeLanguage?redirectTo=en`}
          >
            <button
              type="submit"
              name="lng"
              value={locale === "fr" ? "en" : "fr"}
              className="my-2 flex w-full gap-3 rounded bg-primary  fill-white/40 px-4 py-2 tracking-wide text-white/95 hover:bg-white/10 hover:text-white/80"
            >
              {" "}
              <Icon icon="mdi:translate" fontSize={24} />
              {t("lng")}
            </button>
          </Form>        </div>
        <Form method="POST" className="flex flex-col gap-2">
          <Field
            label={t('username')}
            name='username'
            errors={errors}
          />
          <Field
            type="password"
            label={t('password')}
            name='password'
            errors={errors}
          />

          <div>
            <Link
              to="/forgot"
              className="text-blue-400 underline hover:text-blue-600 text-xs tracking-wide"
            >
              {t('forgotYourPassword?')}
            </Link>
            {
              env.NODE_ENV === 'production' && (
                <TurnstileWidget action="login" onVerify={() => setTurnstileVerified(true)} />
              )
            }       </div>
          <Submit disabled={!turnstileVerified}>{t('signIn')}</Submit>
        </Form>
      </div>
    </div>
  );
}
